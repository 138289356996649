
export function initializeSlider() {

    const slider = document.querySelector('.slider');
    const slides = slider.querySelectorAll('.slide');
    const prevBtn = document.querySelector('.arrow-container.left');
    const nextBtn = document.querySelector('.arrow-container.right');
    let currentSlide = 0;
    let slideInterval;

    function resetTimer() {
        clearInterval(slideInterval);
        slideInterval = setInterval(() => {
            showSlide(currentSlide + 1);
        }, 12000);
    }

    function resetSlideState(slideElement) {
        // Reset any text animations
        const textElements = slideElement.querySelectorAll('.slide-text h4, .slide-text h3, .slide-text h2, .slide-text p, .slide-text li');
        textElements.forEach((element) => {
            // Reset opacity of each letter
            const letters = element.querySelectorAll('.letter');
            letters.forEach((letter) => {
                letter.style.opacity = 1; // Reset opacity to fully visible
                letter.style.transition = ''; // Clear any existing transitions
            });
        });

        // Reset slide container animations
        const textContainer = slideElement.querySelector('.slide-text-container');
        const imageContainer = slideElement.querySelector('.slide-image-container');



        if (textContainer) {
            textContainer.classList.add('inactive');
            textContainer.classList.remove('exit-up', 'exit-down', 'enter-up', 'enter-down');
        }
        if (imageContainer) {
            imageContainer.classList.add('inactive');
            imageContainer.classList.remove('exit-up', 'exit-down', 'enter-up', 'enter-down');
        }

        // Remove any fade effects
        slideElement.classList.remove('fade-out', 'active');
    }

    function animateText(element) {
        const words = element.innerText.split(' ');
        element.innerHTML = ''; // Clear the original text content
        element.style.opacity = 1;

        let letterCount = 0;

        words.forEach((word, wordIndex) => {
            const wordSpan = document.createElement('span');
            wordSpan.className = 'word';

            for (let i = 0; i < word.length; i++) {
                const letterSpan = document.createElement('span');
                letterSpan.className = 'letter';
                letterSpan.innerHTML = word[i];
                letterSpan.style.animationDelay = `${letterCount * 30}ms`;

                // Add different classes based on the element type
                if (element.tagName.toLowerCase() === 'p') {
                    letterSpan.classList.add('fade-only');
                } else {
                    letterSpan.classList.add('fade-and-move');
                }

                wordSpan.appendChild(letterSpan);
                letterCount++;
            }

            element.appendChild(wordSpan);

            if (wordIndex < words.length - 1) {
                element.appendChild(document.createTextNode(' '));
                letterCount++;
            }
        });
    }

    function showSlide(index) {
        const currentSlideElement = slides[currentSlide];
        
        const currentTextContainer = currentSlideElement.querySelector('.slide-text-container');
        const currentImageContainer = currentSlideElement.querySelector('.slide-image-container');

        // Start the exit animation for the current slide
        currentTextContainer.classList.add('exit-up');
        currentImageContainer.classList.add('exit-down');
        currentSlideElement.classList.add('fade-out');

        // Calculate the next slide index
        const nextSlideIndex = (index + slides.length) % slides.length;
        const nextSlideElement = slides[nextSlideIndex];
        const nextTextContainer = nextSlideElement.querySelector('.slide-text-container');
        const nextImageContainer = nextSlideElement.querySelector('.slide-image-container');

        // Prepare next slide's containers
        nextTextContainer.classList.remove('exit-up', 'exit-down', 'enter-up', 'enter-down');
        nextImageContainer.classList.remove('exit-up', 'exit-down', 'enter-up', 'enter-down');

        // Set initial positions for the entry animation
        nextTextContainer.classList.add('enter-down');
        nextImageContainer.classList.add('enter-up');

        // Start the entry animations after exit animations complete
        setTimeout(() => {
            // Remove 'active' from the current slide
            currentSlideElement.classList.remove('active', 'fade-out');
            // Note: Do NOT remove 'exit-up' and 'exit-down' from current slide's containers

            // Activate the next slide
            nextSlideElement.classList.add('active');

            // Trigger the entry animations by removing 'enter' classes
            setTimeout(() => {
                nextTextContainer.classList.remove('enter-down');
                nextImageContainer.classList.remove('enter-up');

                // Animate text elements (fade them in)
                const textElementsHighPriority = nextSlideElement.querySelectorAll(
                    '.slide-text h4, .slide-text h2'
                );
                const textElementsLowPriority = nextSlideElement.querySelectorAll(
                    '.slide-text h3, .slide-text p, .slide-text li'
                );
                textElementsHighPriority.forEach((element, idx) => {
                    setTimeout(() => animateText(element), idx * 150);
                });
                textElementsLowPriority.forEach((element, idx) => {
                    setTimeout(() => animateText(element), idx * 250);
                });

            }, 150); // Slight delay to ensure the entry animation triggers

            // Update the currentSlide index
            currentSlide = nextSlideIndex;
        }, 1200); // Wait for the exit animation to finish before starting the entry
    }

    function handleManualNavigation(nextIndex) {
        // Reset the current slide state
        resetSlideState(slides[currentSlide]);

        // Update the currentSlide index and show the new slide
        showSlide(nextIndex);
    }

    prevBtn.addEventListener('click', (e) => {
        e.preventDefault();
        resetTimer();
        handleManualNavigation(currentSlide - 1);
    });

    nextBtn.addEventListener('click', (e) => {
        e.preventDefault();
        resetTimer();
        handleManualNavigation(currentSlide + 1);
    });

    // Initialize the first slide
    showSlide(0);
    resetTimer();
}