import { initializeSlider } from './slider.js';

document.addEventListener('DOMContentLoaded', async () => {
    const slider = document.querySelector('.slider');
    if (slider) {
        initializeSlider();
    }
    initializePricingCarousel();
    initializeFancyButtons();
    setupConsultationButton();
    setupContactForm();
    checkAccountDeletion();
    setupPhoneLink();
    setupIntersectionObserver();
});

function initializePricingCarousel() {
    const container = document.querySelector('.pricing-container');
    if (container) {
        const cards = container.querySelectorAll('.plan-card');
        const prevBtn = document.querySelector('.carousel-button.prev');
        const nextBtn = document.querySelector('.carousel-button.next');
        let currentIndex = 0;

        function showCard(index) {
            currentIndex = index;
            const cardWidth = cards[0].offsetWidth + 20; // 20px for margin
            const offset = -currentIndex * cardWidth;
            container.style.transform = `translateX(${offset}px)`;
        }

        function nextCard() {
            if (currentIndex < cards.length - 2) {
                showCard(currentIndex + 1);
            } else {
                showCard(0); // Wrap to the first card
            }
        }

        function prevCard() {
            if (currentIndex > 0) {
                showCard(currentIndex - 1);
            } else {
                showCard(cards.length - 2); // Wrap to the last card
            }
        }

        nextBtn.addEventListener('click', nextCard);
        prevBtn.addEventListener('click', prevCard);

        // Show the first card initially
        showCard(0);

        container.style.width = `${3 * (cards[0].offsetWidth + 10)}px`;
    }
}

function initializeFancyButtons() {
    const fancyButtons = document.querySelectorAll('.btn-fancy');

    if (fancyButtons) {

        fancyButtons.forEach(button => {
            button.addEventListener('mousemove', (e) => {
                const rect = button.getBoundingClientRect();
                const x = e.clientX - rect.left;
                const y = e.clientY - rect.top;

                button.style.setProperty('--mouse-x', `${x}px`);
                button.style.setProperty('--mouse-y', `${y}px`);
            });

            button.addEventListener('mouseleave', () => {
                button.style.setProperty('--mouse-x', '0px');
                button.style.setProperty('--mouse-y', '0px');
            });

            // Add click animation
            button.addEventListener('click', function (e) {
                let ripple = document.createElement('span');
                ripple.classList.add('ripple');
                this.appendChild(ripple);
                let x = e.clientX - e.target.offsetLeft;
                let y = e.clientY - e.target.offsetTop;
                ripple.style.left = `${x}px`;
                ripple.style.top = `${y}px`;
                setTimeout(() => {
                    ripple.remove();
                }, 600); // Adjust based on your animation duration
            });
        });
    }
}

const moduleData = [
    {
        image: '../pics/slides/moduleImage1.jpg',
        title: 'Conversation Practice',
        description: 'Engage in natural dialogues with our AI assistant. Build confidence and improve your language skills through interactive mini-lessons.'
    },
    {
        image: '../pics/slides/moduleImage2.jpg',
        title: 'IELTS Speaking Preparation',
        description: 'Practice IELTS speaking questions with instant feedback. Improve your vocabulary, sentence structure, and fluency with personalized suggestions.'
    },
    {
        image: '../pics/slides/moduleImage3.jpg',
        title: 'Complete IELTS Test Simulation',
        description: 'Take full IELTS practice tests covering all four skills. Get detailed feedback and scores to track your progress and identify areas for improvement.'
    },
    {
        image: '../pics/slides/moduleImage4.jpg',
        title: 'Customizable Lesson Creator',
        description: 'For teachers and self-learners: Create tailored lessons with various components like vocabulary exercises, role-plays, and interactive quizzes.'
    }
];

const featureImageMain = document.getElementById('main-feature-image');

if (featureImageMain) {

    let currentIndex = 0;

    // Function to update the main feature image and text
    function changeFeature(index) {
        const featureImage = document.getElementById('main-feature-image');
        const featureTitle = document.getElementById('feature-title');
        const featureDescription = document.getElementById('feature-description');
        const thumbnailFeature = document.getElementById('thumbnail-feature');

        if (featureImage) {

            featureImage.src = moduleData[index].image;
            featureTitle.textContent = moduleData[index].title;
            featureDescription.textContent = moduleData[index].description;

            thumbnailFeature.textContent = getFeatureForThumbnail(index);  // Call to get a feature
            updateThumbnails();  // Call to update the thumbnails
            currentIndex = index;
        }
    }

    function moveLeft() {
        currentIndex = (currentIndex - 1 + moduleData.length) % moduleData.length;
        changeFeature(currentIndex);
    }

    // Function to move right in the carousel
    function moveRight() {
        currentIndex = (currentIndex + 1) % moduleData.length;
        changeFeature(currentIndex);
    }

    document.querySelector('.carousel-arrow.left').addEventListener('click', moveLeft);
    document.querySelector('.carousel-arrow.right').addEventListener('click', moveRight);


    // Function to update the thumbnails, excluding the current one
    function updateThumbnails() {
        const carousel = document.querySelector('.carousel-thumbnails');

        if (carousel) {
            carousel.innerHTML = '';  // Clear current thumbnails

            let visibleThumbnails = moduleData.filter((_, index) => index !== currentIndex); // Filter out current image

            visibleThumbnails.forEach((module, index) => {
                const thumbnailDiv = document.createElement('div');
                thumbnailDiv.classList.add('thumbnail');
                thumbnailDiv.dataset.index = index;

                const thumbnailImg = document.createElement('img');
                thumbnailImg.src = module.image;
                thumbnailImg.alt = module.title;

                const thumbnailTitle = document.createElement('h4');
                thumbnailTitle.textContent = module.title;

                // Append elements to the thumbnail div
                thumbnailDiv.appendChild(thumbnailImg);
                thumbnailDiv.appendChild(thumbnailTitle);


                // Add click event listener for each thumbnail
                thumbnailDiv.addEventListener('click', function () {
                    changeFeature(index);
                });

                // Append thumbnail to carousel
                carousel.appendChild(thumbnailDiv);
            });
        }
    }

    // Function to get a feature for the thumbnail (replace or customize this logic)
    function getFeatureForThumbnail(index) {
        const features = [
            'AI-powered feedback and assistance',
            'Progress tracking and analytics',
            'Downloadable lesson materials',
            'Parent portal with progress reports'
        ];
        return features[index];
    }
    window.addEventListener('DOMContentLoaded', function () {
        changeFeature(currentIndex);  // Set the initial feature image
    });    
}

window.addEventListener('DOMContentLoaded', function () {
    animateText(document.querySelector(".header-text"));
    animateText(document.querySelector("#topText"));
    animateText(document.querySelector("#bottomText"));
});

function animateText(element) {
    const words = element.innerText.split(' ');
    element.innerHTML = ''; // Clear the original text content
    element.style.opacity = 1;

    let letterCount = 0;

    words.forEach((word, wordIndex) => {
        const wordSpan = document.createElement('span');
        wordSpan.className = 'word';

        for (let i = 0; i < word.length; i++) {
            const letterSpan = document.createElement('span');
            letterSpan.className = 'letter';
            letterSpan.innerHTML = word[i];
            letterSpan.style.animationDelay = `${letterCount * 50}ms`;

            wordSpan.appendChild(letterSpan);
            letterCount++;
        }

        element.appendChild(wordSpan);

        if (wordIndex < words.length - 1) {
            element.appendChild(document.createTextNode(' '));
            letterCount++;
        }
    });
}


function setupConsultationButton() {
    const consultationButton = document.getElementById('consultation');
    if (consultationButton) {
        consultationButton.onclick = function () {
            window.location.href = '/Consultations/ConsultationRequest';
        };
    }
}

function setupContactForm() {
    const contactForm = document.getElementById('contactForm');
    if (contactForm) {
        contactForm.addEventListener('submit', function (event) {
            event.preventDefault();
            const formData = new FormData(this);

            fetch('/Consultations/SubmitConsultationRequest', {
                method: 'POST',
                body: formData
            })
                .then(response => response.json())
                .then(data => {
                    if (data.success) {
                        alert('Thank you for your request! We will contact you soon.');
                        this.reset();
                    } else {
                        alert('There was an error processing your request. Please try again.');
                    }
                })
                .catch(error => {
                    console.error('Error:', error);
                    alert('There was an error processing your request. Please try again.');
                });
        });
    }
}

function checkAccountDeletion() {
    const urlParams = new URLSearchParams(window.location.search);
    const isDeleted = urlParams.get('isDeleted');

    if (isDeleted) {
        alert("Your account has been successfully deleted.");
        urlParams.delete('isDeleted');
        const newUrl = window.location.origin + window.location.pathname;
        window.history.replaceState({}, document.title, newUrl);
    }
}

function setupPhoneLink() {
    const phoneLink = document.getElementById('phoneLink');
    const phoneNumber = 'Phone: +84 0909 00 8282';

    if (phoneLink) {
        phoneLink.addEventListener('click', function () {
            phoneLink.textContent = phoneNumber;
        });
    }
}

function setupIntersectionObserver() {
    let landingPage = document.querySelector('.hero h2');

    if (landingPage) {

        let observerOptions = {
            root: null,
            rootMargin: '0px',
            threshold: 0.1
        };

        let observer = new IntersectionObserver((entries, observer) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    entry.target.classList.remove('hidden');
                    entry.target.classList.add('animated');

                    if (entry.target.tagName === 'H2') {
                        entry.target.classList.add('fadeInDown');
                    } else if (entry.target.tagName === 'H3') {
                        entry.target.classList.add('fadeInUp');
                    } else if (entry.target.classList.contains('text-container')) {
                        entry.target.classList.add('fadeInLeft');
                    } else if (entry.target.classList.contains('image-container')) {
                        entry.target.classList.add('fadeInRight');
                    } else if (entry.target.classList.contains('header-container')) {
                        entry.target.classList.add('fadeInPlace');
                    }
                } else {
                    entry.target.classList.add('hidden');
                    entry.target.classList.remove('animated', 'fadeInDown', 'fadeInUp', 'fadeInLeft', 'fadeInRight', 'fadeInPlace');
                }
            });
        }, observerOptions);

        let targets = document.querySelectorAll('.bottom-cta h2, .bottom-cta h3, .hero h2, .hero h3, .text-container, .image-container, .header-container');
        targets.forEach(target => observer.observe(target));
    }
}